<template>
    <div class="contacts">
        <div class="contacts-search">
            <a-input v-if="showSearch" v-model="indexName" @change="handleIndexName" :placeholder="activeKey == 'customer'?'请输入客户名称':'请输入客户或群聊名称'" style="width:290px;margin-right:10px" />
            <div v-else class="contacts-search-count">
                <a-icon type="appstore" />
                <span v-if="activeKey == 'customer'" style="margin-left:5px">共有{{chatCount.toLocaleString()}}个客户</span>
                <span v-else style="margin-left:5px">共有{{chatCount.toLocaleString()}}个会话</span>
            </div>
            <div @click="toShowSearch" class="contacts-search-icon">
                <span v-if="showSearch">取消</span>
                <a-icon v-else type="search" />
            </div>
        </div>
        <div 
            class="contacts-list"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            ref="scrollbox"
        >
            <vue-element-loading :active="loading && list.length ==0" color="#00cca2" spinner="spinner"/>
            <a-list item-layout="horizontal" :data-source="list">
                <a-list-item @mouseover="handleOver(index,2)" @mouseout="handleOut(index)" :class="{'contacts-item-bg':checkIndex == index,'contacts-item-bgF':chatIndex == index}" slot="renderItem" slot-scope="item, index">
                    <div @click="changeChat(item,index)" class="contacts-item">
                        <div style="margin:0 10px">
                            <img style="border-radius:2px" v-if="item.session_type == 'staff_group'" src="@/assets/neibuqunliao.svg" />
                            <img style="border-radius:2px" v-if="item.session_type == 'external_group'" src="@/assets/qunliao.svg" />
                            <a-avatar v-if="item.session_type != 'staff_group' && item.session_type != 'external_group'" shape="square" :size="46" icon="user" :src="item.avatar" />
                        </div>
                        <div style="flex:1">
                            <div class="contacts-item-info">
                                <span class="contacts-item-name">{{item.name}}</span>
                                <img style="margin-left:5px" v-if="item.session_type == 'external_group' || item.session_type == 'ext_user'" src="@/assets/out.svg">
                                <span class="contacts-item-time">{{item.msgtime_txt}}</span>
                            </div>
                            <!-- <div class="contacts-item-text" v-html="convertText2xEmoji(item.last_msg_thumb)"></div> -->
                            <div class="contacts-item-text">{{item.last_msg_thumb}}</div>
                        </div>
                    </div>
                </a-list-item>
                <div slot="footer">
                    <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                    <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                </div>
            </a-list>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import infiniteScroll from 'vue-infinite-scroll'
    import { parsingEmoji } from '@/utils/emjoymethod'
    import '@/styles/emjoy.css'
    import { delay } from '@/utils/timeFormat'
    export default {
        name:'groupContacts',
        inject: ['parent'],
        data() {
            return {
                myHeight:0,
                busy:true,
                customer_busy:true,
                loading:false,
                list:[],
                customer_list:[],
                activeKey:'all',
                staffList:[],
                searchParams: {
                    "page": 1,
                    "per-page": 20,
                    "search": {},
                    staff_ext_id:this.$ls.get('wxwork_userid')
                },
                customerParams:{
                    "page": 1,
                    "per-page": 20,
                    "search": {},
                },
                chatIndex:-1,
                checkIndex:0,
                wxwork_info:this.$ls.get('wxwork_info'),
                name:'',
                indexName:'',
                showSearch:false,
                chatCount:0,
                staffCount:0,
                staffIndex:-1,

            }
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 300}px`
            },
        },
        directives: { infiniteScroll },
        created () {
            this.myHeight = this.windowHeight()
            this.getList(1)
            var that = this
            document.onkeydown = function(e) {
                let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
                //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                if(e1 && e1.keyCode == 40){
                    if(that.checkIndex != that.list.length-1 && that.list.length>0){
                        that.checkIndex = that.checkIndex + 1
                        that.changeChat(that.list[that.checkIndex],that.checkIndex)
                        that.$refs.scrollbox.scrollTop = that.$refs.scrollbox.scrollTop + 71
                    }
                }
                if(e1 && e1.keyCode == 38){
                    if(that.checkIndex != 0 && that.list.length>0){
                        that.checkIndex = that.checkIndex - 1
                        that.changeChat(that.list[that.checkIndex],that.checkIndex)
                        that.$refs.scrollbox.scrollTop = that.$refs.scrollbox.scrollTop - 71
                    }
                }
            }
        },
        methods: {
            moment,
            async getList(val){
                this.loading = true
                this.searchParams.session_type = this.activeKey
                this.searchParams.name = this.indexName
                if(val && val === 1){
                    this.list = []
                    this.chatIndex = 0
                    this.checkIndex = 0
                    this.searchParams.page = 1
                }
                await this.$store.dispatch('wxworkMsgRoomAction', {data:this.searchParams})
                .then(res=>{
                    this.chatCount = res._meta.totalCount
                    if(res.items.length >0){
                        if(this.searchParams.page == 1){
                            this.list = res.items
                            this.busy = false
                        }else{
                            this.list = [...this.list,...res.items]
                        }
                    }else{
                        this.busy = true
                    }
                    if(val && res.items.length >0){
                        this.parent.changeGroupValue(this.searchParams.staff_ext_id,res.items[0])
                    }
                    this.pageParams = res._meta
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async getContact(){
                this.loading = true
                this.customer_busy = true
                this.customerParams.staff_ext_id = this.searchParams.staff_ext_id
                await this.$store.dispatch('wxworkMsgContactAction', {data:this.customerParams})
                .then(res=>{
                    this.chatCount = res._meta.totalCount
                    if(res.items.length >0){
                        if(this.customerParams.page == 1){
                            this.customer_list = res.items
                            this.customer_busy = false
                        }else{
                            this.customer_busy = false
                            this.customer_list = [...this.customer_list,...res.items]
                        }
                    }else{
                        this.customer_busy = true
                    }
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async changeStaff(item){
                this.wxwork_info = item
                this.searchParams.staff_ext_id = item.userid
                this.busy = true
                this.list = []
                this.chatIndex = 0
                this.checkIndex = 0
                this.chatCount = 0
                this.searchParams.page = 1
                this.customer_busy = true
                this.customer_list = []
                this.customerParams.page = 1
                if(this.activeKey === 'customer'){
                    this.getContact()
                }else{
                    this.getList(1)
                }
            },
            changeChat(item,index){
                this.parent.changeGroupValue(this.searchParams.staff_ext_id,item)
                this.checkIndex = index
            },
            async handleInfiniteOnLoad(){
                if(!this.busy){
                    await this.searchParams.page++
                    await this.getList()
                }
            },
            async handleInfiniteOnLoad2(){
                if(!this.customer_busy){
                    await this.customerParams.page++
                    await this.getContact()
                }
            },
            changeTab(key) {
                this.activeKey = key
                if(key === 'customer'){
                    this.chatCount = 0
                    this.customer_busy = true
                    this.customer_list = []
                    this.customerParams.page = 1
                    this.getContact()
                }else{
                    this.chatCount = 0
                    this.busy = true
                    this.list = []
                    this.chatIndex = 0
                    this.checkIndex = 0
                    this.searchParams.page = 1
                    this.loading = true
                    delay(() => {
                        this.getList(1)
                    }, 500)
                }
            },
            handleIndexName(){
                this.busy = true
                this.list = []
                this.chatIndex = 0
                this.checkIndex = 0
                this.searchParams.page = 1
                this.getList(1)
            },
            handleOver(index,num){
                if(num === 1){
                    this.searchParams.page = 1
                    this.staffIndex = index
                }else{
                    this.chatIndex = index
                }
                
            },
            handleOut(index){
                this.staffIndex = -1
                this.chatIndex = -1
            },
            convertText2Emoji(text){
                return parsingEmoji(text)
            },
            toShowSearch(){
                this.indexName = ''
                if(this.showSearch){
                    this.handleIndexName()
                }
                this.showSearch = !this.showSearch
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
        },
        beforeDestroy () {
            document.onkeydown = undefined
        },
    }
</script>

<style lang="scss">
    .contacts-tabs{
        .ant-tabs-bar{
            margin: 0;
        }
    }
    .contacts-staff{
        background: #fff;
        width: 100%;
        box-shadow: 0 2px 8px rgba(0,0,0,.15);
        border-radius: 4px;
        padding: 10px;
        &-nav{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
        &-warp{
            max-height: 321px;
            overflow-y: auto;
        }
        &-quit{
            width: 20px;
            position: absolute;
            top: 8px;
            left: 8px;
        }
        &-item{
            padding: 8px;
            border-radius:2px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
        }
        &-bg{
            background: #e6fff6;
        }
    }
</style>

<style lang="scss" scoped>
    .contacts{
        border-right:1px solid #eee;
        .contacts-nav{
            display: flex;
            align-items: center;
            padding: 10px 0;
            cursor: pointer;
            position: relative;
            &-quit{
                width: 20px;
                position: absolute;
                top: 10px;
                left: 0px;
            }
        }
        .contacts-search{
            margin: 10px 0;
            display: flex;
            align-items: center;
            &-count{
                height: 32px;
                line-height: 32px;
                width: 300px;
            }
            &-icon{
                height: 32px;
                line-height: 32px;
                cursor: pointer;
            }
        }
        .contacts-list{
            overflow-y: scroll;
        }
        .contacts-item{
            display: flex;
            width: 100%;
            cursor: pointer;
            &-bg{
                background: aliceblue;
            }
            &-bgF{
                background: aliceblue;
            }
            &-info{
                position: relative;
                display: flex;
                align-items: center;
            }
            &-name{
                display: inline-block;
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                color: #222;
            }
            &-time{
                position: absolute;
                right: 15px;
                font-size: 12px;
                color: rgba(0,0,0,.65);
            }
            &-tag{
                width: 26px;
                height: 15px;
                background: darkcyan;
            }
            &-text{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                width: 280px;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }
</style>
<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="450px"
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index"  :label="item.label">
                <div>
                    <a-popover v-model="isCheckWarp" title="选择" trigger="click">
                        <div slot="content">
                        <checkWarp />
                        </div>
                        <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
                        <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.name}}</a-tag>
                        <a-icon style='fontSize:18px' type="plus-circle" />
                        </div>
                    </a-popover>
                </div>
            </a-form-item>
        </a-form>
  </a-modal>
</template>
<script>
const formData = [
    {
        name: 'invalid_keywords_notify_ids',
        label: '通知账号',
        type: 'select',
        rules: [],
        items: {
            data: 'consultants',
            label: 'name',
            value: 'userid'
        }
    },
]
    import checkWarp from './CheckWarp'
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible:false,
                confirmLoading:false,
                modalTitle: '提醒通知',
                form: this.$form.createForm(this),
                consultants:[],
                tagsList:[],
                inputVisible:false,
                isCheckWarp:false,
                inputValue:'',
                check_name_list:[],
                send_employee_ids:[]
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        props:{
            item:Array
        },
        components: {
            checkWarp,
        },
        created () {
            this.check_name_list = this.item
            let send_employee_list = this.item
            this.item.forEach(item=>{
                this.send_employee_ids.push(item.userid)
            })
            this.$ls.set('check_name_list',JSON.stringify(this.check_name_list))
            this.$ls.set('send_employee_ids',JSON.stringify(this.send_employee_ids))
            this.$ls.set('send_employee_list',JSON.stringify(send_employee_list))
            this.visible = true
            this.getConsultant()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getConsultant() {
                let res = await this.$store.dispatch('wxworkStaffIndexAction', {})
                this.consultants = res.items
            },
            handleInputChange(e) {
                this.inputValue = e.target.value
            },
            showInput() {
                this.inputVisible = true
            },
            handleInputConfirm(){
                if(this.inputValue){
                    this.tagsList.push(this.inputValue)
                }
                this.inputVisible = false
                this.inputValue = ''
                this.form.setFieldsValue({invalid_keywords:this.tagsList})
            },
            handleClose(removedTag) {
                const tagsList = this.tagsList.filter(tag => tag !== removedTag)
                this.tagsList = tagsList
                this.form.setFieldsValue({invalid_keywords:this.tagsList})
            },
            async handleOk() {
                this.confirmLoading = true
                await this.$store.dispatch('settingWxworkSetKeywordsAction', {data:{invalid_keywords_notify_ids:this.send_employee_ids}})
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            hideCheckWarp(val){
                if(val){
                    this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                    this.send_employee_ids = JSON.parse(this.$ls.get('send_employee_ids'))
                }
                this.isCheckWarp = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    .tags-warp{
        display: flex;
    }
</style>
<template>
    <div>
        <div style="padding-top:10px;" class="clearfix table-tools">
            <div class="search">
                <a-form layout='inline' @submit="toSearch">
                    <a-form-item>
                        <a-select style="width: 200px" v-model='searchParams.staff_ext_id' allowClear showSearch @change="handleUserChange" placeholder="请选择员工" :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['userid']">{{ d['name'] }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-input v-model="searchParams.keyword" placeholder="客户名称" style="flex:1;width: 160px" allowClear />
                    </a-form-item>
                    <a-form-item>
                        <a-tree-select
                            style="width:200px"
                            v-model="searchParams.search.tag_id"
                            :tree-data="tags"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            :maxTagTextLength='5'
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择企业微信标签"
                        />
                    </a-form-item>
                    <a-form-item>
                        <a-range-picker
                        :ranges="rangesData"
                        v-model='searchDatas.add'
                        :disabled-date="disabledDate"
                        allowClear
                        style="width: 240px"
                        @change="(val,time)=>handleTime(val,time,'add')" />
                    </a-form-item>
                    <!-- <a-form-item>
                        <a-radio-group :value="searchDatas.follow_status">
                            <a-radio-button @click="handleTypeButChange('1')" value="1">
                                流失记录
                            </a-radio-button>
                        </a-radio-group>
                    </a-form-item> -->
                    <a-form-item>
                        <a-button @click="toSearch" :loading="loading" html-type="submit" type="primary" >搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div
            class="recordList-list"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10">
            <vue-element-loading :active="loading && list.length==0" color="#00cca2" spinner="spinner"/>
            <a-list :data-source="list">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <div class="recordList-list-item" @click="clickUnfollow(item)">
                        <div class="recordList-list-item-user">
                            <div class="recordList-list-item-add-to">
                                <div>
                                    <a-avatar style="margin-right:10px" shape="square" :size="32" icon="user" :src="item.unfollow_by.contact_avatar" />
                                </div>
                                <div style="width:260px;">
                                    <div>{{item.unfollow_by.contact_name}}</div>
                                    <div style="font-size: 12px;">
                                        <span v-if="item.unfollow_by.contact_name_remark">{{item.unfollow_by.contact_name_remark}}</span>
                                        <span style="margin-right:5px" v-for="(it,j) in item.unfollow_by.contact_department" :key="j">{{it}}</span>
                                        <span v-if="item.unfollow_by.corp_name" style="margin-right:5px;color:#faad14">@{{item.unfollow_by.corp_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <span style="margin:0 150px 0 50px;font-size:12px;">取消了好友</span>
                            <div class="recordList-list-item-add-to">
                                <div>
                                    <a-avatar style="margin-right:10px" shape="square" :size="32" icon="user" :src="item.unfollow_to.contact_avatar" />
                                </div>
                                <div style="width:260px;">
                                    <div>{{item.unfollow_to.contact_name}}</div>
                                    <div style="font-size: 12px;">
                                        <span v-if="item.unfollow_to.contact_name_remark">{{item.unfollow_to.contact_name_remark}}</span>
                                        <span style="margin-right:5px" v-for="(it,j) in item.unfollow_to.contact_department" :key="j">{{it}}</span>
                                        <span v-if="item.unfollow_to.corp_name" style="margin-right:5px;color:#faad14">@{{item.unfollow_to.corp_name}}</span>
                                    </div>
                                </div>
                                <div style="margin-left:100px">
                                    <a-tag class="recordList-list-tag" style="margin:0 5px" v-for="(it,j) in item.tag_list" :key="j">{{it.tag_name}}</a-tag>
                                </div>
                            </div>
                        </div>
                        <div>
                            {{item.unfollow_time}}
                        </div>
                    </div>
                </a-list-item>
                <div slot="footer">
                    <vue-element-loading :active="loading && list.length>0" color="#00cca2" spinner="spinner"/>
                    <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                </div>
            </a-list>
        </div>

        <chatModal v-if="isChatModal" :item="modalData" />
        
    </div>
</template>

<script>
    import moment from 'moment'
    import chatModal from '../keywords/chatModal'
    import { TreeSelect } from 'ant-design-vue'
    import { delay } from '@/utils/timeFormat'
    const SHOW_ALL = TreeSelect.SHOW_ALL
    import ranges from "@/common/mixins/ranges"
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'unfollowLog',
        data() {
            return {
                SHOW_ALL,
                busy:false,
                loading:false,
                isChatModal:false,
                modalData:[],
                list: [],
                tags: [],
                studios: [],
                myHeight:0,
                searchDatas:{},
                searchParams: {
                    "page": 0,
                    "per-page": 30,
                    "search": {},
                    "sort": ''
                },
            }
        },
        components: {
            chatModal,
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 250}px`
            }
        },
        directives: { infiniteScroll },
        mixins:[ranges],
        created () {
            this.myHeight = this.windowHeight()
            this.getTag()
            this.getStaff()
        },
        methods: {
            hideVideoModal(){
                
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].text.indexOf(input) >= 0;
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            hideChatModal(){
                this.isChatModal=false;
            },
            clickUnfollow(item){
                this.modalData={
                    id:0,
                    name:item.unfollow_by.contact_name,
                    from:item.unfollow_by.external_userid,
                    tolist:item.unfollow_to.external_userid,
                    toname:item.unfollow_to.contact_name,
                    session_type:'ext_user',
                }
                this.isChatModal=true;
            },
            async getList(){
                this.loading = true
                this.searchParams['per-page'] = 30
                await this.$store.dispatch('wxworkMsgUnfollowHistoryAction', {data:this.searchParams})
                .then(res=>{
                    if(this.list.length == 0){
                        this.list = res.items
                    }else{
                        this.list = [...this.list,...res.items]
                    }
                    if(this.searchParams.page >= res._meta.pageCount){
                        this.busy = true
                    }
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async getStaff(val){
                let obj = {name:val}
                let res = await this.$store.dispatch('wxworkStaffIndexAction', {data:obj})
                this.studios = res.items
            },
            async getTag(){
                let res = await this.$store.dispatch('wxworkMsgTagAction', {})
                this.tags = res.data
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            toSearch(e){
                e?e.preventDefault():''
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                delay(() => {
                    this.getList()
                }, 200)
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleUserChange(){
                this.toSearch()
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
                this.toSearch()
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            handleTypeButChange(e){
                if(this.searchDatas.follow_status == e){
                    this.searchDatas.follow_status = undefined
                    this.searchParams.search.follow_status = undefined
                }else{
                    this.searchDatas.follow_status = e
                    this.searchParams.search.follow_status = [0,-1]
                }
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                this.getList()
            },
        },
    }
</script>

<style lang="scss" scoped>
    .recordList-list{
        overflow-y: scroll;
        .recordList-list-item{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &-user{
                display: flex;
                align-items: center;
            }
        }
        .recordList-list-item-add-to{
            display: flex;
            align-items: center;
        }
        &-tag{
            color: #999 !important;
            border-color: #f5f7fa !important;
            border-bottom-left-radius: 11px;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            border-bottom-right-radius: 11px;
        }
    }
</style>
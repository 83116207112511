<template>
    <div>
        <a-drawer
            :title="chatItem.name"
            placement="right"
            :closable="false"
            :visible="visible"
            @close="onClose"
            width='340px'
            wrapClassName="detailDrawer"
            >
                <div class="detailDrawer-nav">
                    <a-divider style="width:3px;background:#00cca2;top:1px" type="vertical" />
                    <div>群成员({{chatItem.tolist.length + 1}})</div>
                    <a-input class="detailDrawer-nav-inp" v-model="keyword" @change="handleChange" placeholder="搜索群成员" />
                </div>
                <div class="detailDrawer-staff">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <div style="display:flex;align-items:center" v-if="list.length > 0"><span>内部联系人</span> <span style="margin-left:5px;color:#00cca2">({{list.length}})</span></div>
                    <div v-for="item in list" :key="item.userid" class="detailDrawer-staff-item">
                        <a-avatar style="cursor:pointer" @click="changeStaff(item)" shape="square" :size="34" icon="user" :src="item.avatar" />
                        <span class="detailDrawer-staff-name">{{item.name}}</span>
                    </div>
                    <div style="display:flex;align-items:center" v-if="extList.length > 0"><span>外部联系人</span><span style="margin-left:5px;color:#00cca2">({{extList.length}})</span></div>
                    <div v-if="extList.length > 0" class="detailDrawer-staff-remind">
                        <a-divider style="width:2px;background:#00cca2;" type="vertical" />
                        <span>经外部联系人同意后才会保存联系人发送的会话内容</span>
                    </div>
                    <div v-for="item in extList" :key="item.userid" class="detailDrawer-staff-item">
                        <a-badge :dot="item.student_id?true:false">
                            <a-avatar style="cursor:pointer" @click="showProfileModal(item)" shape="square" :size="32" icon="user" :src="item.avatar" />
                        </a-badge>
                        <span class="detailDrawer-staff-name">
                            {{item.remark}}({{item.name}})
                        </span>
                        <!-- <a style="margin-left:5px;font-size:12px" v-if="item.type == 1" href="javascript:;">@微信</a>
                        <a style="margin-left:5px;font-size:12px" v-if="item.type == 2" href="javascript:;">@企业微信</a> -->
                        <span class="detailDrawer-staff-agree" v-if="item.agree_msgaudit">已同意</span>
                        <span class="detailDrawer-staff-agree" v-else>未同意</span>
                    </div>
                </div>
        </a-drawer>
        <profileModal v-if="profileVisible" :studentId="studentId"/>
    </div>
</template>

<script>
    import profileModal from '@/views/modal/profile'
    export default {
        name:'detailDrawer',
        inject: ['parent'],
        provide() {
            return {
            parent: this
            }
        },
        data() {
            return {
                visible: true,
                loading: true,
                list:[],
                extList:[],
                itemList:[],
                keyword:'',
                profileVisible: false,
                studentId:''
            }
        },
        props: {
            chatItem:Object,
            staff_ext_id:String
        },
        components: {
            profileModal,
        },
        created () {
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                let obj = {
                    staff_ext_id:this.staff_ext_id,
                    session_ext_id:this.chatItem.session_ext_id
                }
                let res = await this.$store.dispatch('wxworkMsgGroupMemberAction',{data:obj})
                let list = []
                let extList = []
                res.data.forEach(item=>{
                    if(item.type === 0){
                        list.push(item)
                    }else{
                        extList.push(item)
                    }
                })
                this.list = list
                this.extList = extList
                this.itemList = res.data
                this.loading = false
            },
            onClose() {
                this.parent.isDetailDrawer = false
            },
            handleChange(){
                this.loading = true
                let arr = []
                let list = []
                let extList = []
                if(this.keyword){
                    arr = this.itemList.filter((item)=>{
    　　　　            return item.name.indexOf(this.keyword) != -1
                    })
                }else{
                    arr = this.itemList
                }
                console.log(arr)
                arr.forEach(item=>{
                    if(item.type === 0){
                        list.push(item)
                    }else{
                        extList.push(item)
                    }
                })
                this.list = list
                this.extList = extList
                this.loading = false
            },
            changeStaff(item){
                console.log(item)
                this.parent.changeStaff(item)
            },
            showProfileModal(item) {
                if(item.student_id){
                    this.studentId = item.student_id
                    this.profileVisible = true
                }
            },
            hideProfileModal() {
                this.profileVisible = false
            },
        },
    }
</script>

<style lang="scss">
    .detailDrawer{
        .ant-drawer-title{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
        }
        .ant-drawer-body{
            padding: 12px;
        }
        &-nav{
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 10px;
            &-inp{
                width: 160px;
                position: absolute;
                right: 0px;
                height: 32px;
            }
        }
        &-staff{
            width: 100%;
            &-item{
                padding:12px 0;
                position: relative;
                display: flex;
                align-items: center;
            }
            &-remind{
                font-size: 12px;
                background: #f6f6f6;
                padding: 2px 0;
                margin-top: 5px;
                color: rgba(0,0,0,.45);
            }
            &-agree{
                position: absolute;
                right: 0;
                font-size: 12px;
                color: rgba(0,0,0,.45);
            }
            &-name{
                display: inline-block;
                width: 200px;
                margin-left:10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
            .ant-badge-dot{
                background: #00cca2 !important;
            }
        }
    }
</style>
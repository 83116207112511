<template>
  <a-modal :title="modalTitle" v-model="visible"
    cancelText="取消" okText="确认" width="750px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
        <checkRecord :staff_ext_id="item.from" :chatItem="item" wHeight="650px" :msgID="item.id" />
  </a-modal>
</template>

<script>
    import checkRecord from '@/views/orginization/messages/checkRecord'
    export default {
        name:'chatModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                modalTitle:''
            }
        },
        props:{
            item:Object
        },
        components: {
            checkRecord,
        },
        created () {
            this.visible = true
            this.modalTitle = `${this.item.name} 与 ${this.item.toname}的聊天记录`
        },
        methods: {
            handleOk() {
                
            },
            handleCancel(){
                this.parent.hideChatModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
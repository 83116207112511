<template>
    <div>
        <a-breadcrumb class="breadcrumb" style="padding: 15px 0px">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>校区管理</a-breadcrumb-item>
            <a-breadcrumb-item>会话存档</a-breadcrumb-item>
        </a-breadcrumb>

        <div class="main-box" style="padding: 0px 10px">
            <a-tabs :animated="false" :activeKey="acitveKey" @change="changeTab" :tabBarStyle="{margin:0}">
                <a-tab-pane tab="会话存档" key="1">
                    <div v-if="acitveKey === '1'" class="messages" >
                        <div class="messages-left">
                            <contacts ref="contacts" />
                        </div>
                        <!-- <div class="messages-left">
                            <contacts ref="contacts" />
                        </div> -->
                        <div class="messages-right">
                            <chatRecord :staff_ext_id="staff_ext_id" :chatItem="chatItem" :isTab="1" />
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="群聊会话" key="5">
                    <div v-if="acitveKey === '5'" class="messages" >
                        <div class="messages-left">
                            <groupContacts ref="groupContacts" />
                        </div>
                        <div class="messages-right">
                            <chatRecord :staff_ext_id="staff_ext_id" :chatItem="chatGroupItem" :isTab="2" />
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="会话记录" key="7">
                    <chats v-if="acitveKey === '7'"/>
                </a-tab-pane>
                <a-tab-pane tab="关键词提醒" key="2">
                    <keywords v-if="acitveKey === '2'"/>
                </a-tab-pane>
                <a-tab-pane tab="会话统计" key="3">
                    <statistics v-if="acitveKey === '3'"/>
                </a-tab-pane>
                <a-tab-pane tab="好友记录" key="4">
                    <recordList :staff_ext_id="staff_ext_id" v-if="acitveKey === '4'"/>
                </a-tab-pane>
                <a-tab-pane tab="流失记录" key="6">
                    <unfollowLog :staff_ext_id="staff_ext_id" v-if="acitveKey === '6'"/>
                </a-tab-pane>
            </a-tabs>
        </div>
        <detailDrawer :staff_ext_id="staff_ext_id" :chatItem="detailDrawerData" v-if="isDetailDrawer"/>
    </div>
</template>

<script>
    import contacts from './contacts'
    import groupContacts from './groupContacts'
    import chatRecord from './chatRecord'
    import detailDrawer from './detailDrawer'
    import statistics from './statistics'
    import recordList from './recordList'
    import unfollowLog from './unfollowLog'
    import keywords from '../keywords'
    import chats from '../chats'
    export default {
        name:"messages",
        data() {
            return {
                staff_ext_id:'',
                chatItem:{},
                chatGroupItem:{},
                detailDrawerData:{},
                isDetailDrawer:false,
                acitveKey: '1',
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            contacts,
            chatRecord,
            detailDrawer,
            keywords,
            chats,
            statistics,
            recordList,
            unfollowLog,
            groupContacts
        },
        methods: {
            changeTab(key) {
                this.acitveKey = key
            },
            changeValue(staff_ext_id,item) {
                this.staff_ext_id = staff_ext_id
                this.chatItem = item
            },
            changeGroupValue(staff_ext_id,item) {
                this.staff_ext_id = staff_ext_id
                this.chatGroupItem = item
            },
            changeStaff(item,num){
                this.isDetailDrawer = false
                if(num == 1){
                    this.$refs.contacts.changeStaff(item)
                }else{
                    this.$refs.groupContacts.changeStaff(item)
                }
            },
            showDetailDrawer(num){
                this.isDetailDrawer = true
                if(num == 1){
                    this.detailDrawerData = this.chatItem
                }else{
                    this.detailDrawerData = this.chatGroupItem
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .messages{
        display: flex;
        .messages-left{
            width: 400px;
        }
        .messages-right{
            flex: 1;
        }
    }
</style>
<template>
  <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-heatmap 
              :data="item" 
              judge-width
              :title="{
                  text:`${title}（${item.title?item.title:''}）`,
                  subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                  textStyle: {
                      fontSize: 15,
                  },
                  subtextStyle: {
                      fontSize: 12.5
                  }
              }"
              :extend="{
                series:{
                  itemStyle:{
                    borderColor:'#fff',
                    borderWidth:1
                  }
                }
              }"
              :settings="chartSettings" 
              :visual-map="chartVisualMap" 
              :grid="chartGrid"
              >
              <div class="heatmap-radio">
                <a-radio-group v-model="type" @change="onChange">
                  <a-radio-button :value="0">
                    消息发送
                  </a-radio-button>
                  <a-radio-button :value="1">
                    私信接收
                  </a-radio-button>
                </a-radio-group>
              </div>
            </ve-heatmap>
        </div>
    </a-card>
</template>

<script>
  import moment from 'moment'
  export default {
    name:'heatmap',
    inject: ['parent'],
    data () {
      this.chartSettings = {
        yAxisList: ['23:00','22:00','21:00','20:00','19:00','18:00','17:00','16:00','15:00','14:00','13:00','12:00','11:00','10:00','9:00','8:00','7:00','6:00','5:00','4:00','3:00','2:00','1:00','0:00']
      }
      this.chartGrid = {
        right: 10
      }
      this.chartVisualMap = {
        show: true,
        min: 0,
        max: 1000,
        type: 'piecewise',
        orient:'horizontal',
        left:"center",
        pieces: [
                {
                    gt: 1,   //大于3 小于等于6
                    lte: 20,
                },{
                    gt: 20,   //大于3 小于等于6
                    lte: 50,
                }, {
                    gt: 50,   //大于3 小于等于6
                    lte: 100,
                }, {
                    gt: 100,   //大于3 小于等于6
                    lte: 150,
                }, {
                    gt: 150,   //大于3 小于等于6
                    lte: 200,
                }, {
                    gt: 200,   //大于3 小于等于6
                    lte: 250,
                }, {
                    gt: 250,   //大于3 小于等于6
                    lte: 300,
                }, {
                    gt: 300,   //大于3 小于等于6
                    lte: 350,
                }, {
                    gt: 350,   //大于3 小于等于6
                    lte: 400,
                }, {
                    gt: 400,   //大于400
                }
         ],
      }
      return {
        type:0
      }
    },
    props:['item','loading','title','start_date','end_date'],
    methods: {
      onChange(e) {
        this.parent.screenHeatmap(e.target.value)
      }
    },
  }
</script>

<style lang="scss">
  .heatmap-radio{
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #888;
      font-size: 14px;
  }
</style>
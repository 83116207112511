<template>
    <div>
        <div class="checkwarp">
            <div class="warp">
                <a-radio-group v-model="mode" :style="{ marginBottom: '8px' }">
                    <a-radio-button value="1">
                        员工
                    </a-radio-button>
                </a-radio-group>
                <div v-if="mode === '1'" class="checkwarp-box">
                    <a-input-search placeholder="搜索员工" style="width: 170px" @change='handleEmployee' @search="onSearchEmployee" />
                    <div class="checkwarp-user">
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <div style="margin:5px 0" v-for="item in staffList" :key="item.userid">
                            <a-checkbox :checked="item.isCheck" @click="toCheckEmployee(item)">{{item.name}}</a-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="warp" style="margin-left:15px">
                <div style="position:relative;height: 32px;margin-bottom:8px;line-height:32px">
                    <span>已选择员工({{send_employee_ids.length}})</span>
                    <span @click="toClear" style="position:absolute;right:0">清空</span>
                </div>
                <div class="checkwarp-box">
                    <div class="checkwarp-user">
                        <div style="margin:5px 0;position: relative;display: flex;align-items: center;" v-for="(item,index) in check_name_list" :key="index">
                            <span>{{item.name}}</span>
                            <a-icon @click="toDelete(item)" style="position:absolute;right:0" type="delete" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align:right;padding:5px 0">
            <a-button @click="confirm" type="primary">确认</a-button>
            <a-button @click="hideCheckWarp" style="margin-left:10px">取消</a-button>
        </div>
    </div>
</template>

<script>
    export default {
        name:'CheckWarp',
        inject: ['parent'],
        data() {
            return {
                loading:false,
                mode: '1',
                staffList:[],
                send_employee_ids:[],
                send_employee_list:[],
                check_name_list:[],
            }
        },
        created () {
            this.check_name_list = JSON.parse(this.$ls.get('check_name_list')) || []
            this.send_employee_ids = JSON.parse(this.$ls.get('send_employee_ids')) || []
            this.send_employee_list = JSON.parse(this.$ls.get('send_employee_list')) || []
            this.getStaff()
        },
        methods: {
            async getStaff(val){
                this.loading = true
                let obj = {name:val,search:{status:1}}
                let res = await this.$store.dispatch('wxworkStaffIndexAction', {data:obj})
                this.staffList = res.items
                this.screenEmployee(res.items)
                this.loading = false
            },
            screenEmployee(arr){
                arr.forEach(item=>{
                    item.isCheck = false
                })
                arr.forEach(item=>{
                    this.send_employee_ids.forEach(it=>{
                        if(item.userid == it){
                            item.isCheck = true
                        }
                    })
                })
                return arr
            },
            handleEmployee(e){
                this.getStaff(e.target.value)
            },
            onSearchEmployee(e) {
                this.getStaff(e)
            },
            toCheckEmployee(item){
                if(item.isCheck){
                    item.isCheck = false
                    let index = this.send_employee_ids.indexOf(item.userid)
                    this.send_employee_ids.splice(index,1)
                    this.send_employee_list.splice(index,1)
                }else{
                    item.isCheck = true
                    this.send_employee_ids.push(item.userid)
                    this.send_employee_list.push(item)
                }
                this.check_name_list = this.send_employee_list
                this.$forceUpdate()
            },
            onSearch(e){

            },
            toClear(){
                this.check_name_list = []
                this.send_employee_ids = []
                this.send_employee_list = []
                this.staffList.forEach(item=>{
                    item.isCheck = false
                })
                this.$ls.remove('check_name_list')
                this.$ls.remove('send_employee_ids')
                this.$ls.remove('send_employee_list')
            },
            toDelete(item){
                if(item.userid){
                    let index = this.send_employee_ids.indexOf(item.userid)
                    this.send_employee_ids.splice(index,1)
                    this.send_employee_list.splice(index,1)
                    this.staffList.forEach(it=>{
                        if(it.userid == item.userid){
                            it.isCheck = false
                        }
                    })
                }
                this.check_name_list = [...this.send_employee_list]
                this.$forceUpdate()
            },
            hideCheckWarp(){
                this.parent.hideCheckWarp()
            },
            confirm(){
                this.$ls.set('check_name_list',JSON.stringify(this.check_name_list))
                this.$ls.set('send_employee_ids',JSON.stringify(this.send_employee_ids))
                this.$ls.set('send_employee_list',JSON.stringify(this.send_employee_list))
                this.parent.hideCheckWarp(1)
            }
        },
    }
</script>

<style lang="scss">
    .checkwarp{
        display: flex;
        .warp{
            width: 250px;
        }
        .checkwarp-box{
            height: 340px;
            padding: 15px;
            border: 1px solid #ebedf0;
        }
        .checkwarp-user{
            height: 250px;
            overflow-y: auto;
        }
    }
</style>